#past-reports-sticky-container {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  top: 67px;
  bottom: 74px;
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  overflow-x: hidden;
}

#past-reports-sticky-div {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #f0f0f0;
  z-index: 1;
}

#past-reports-footer {
  position: fixed;
  width: 100%;
  height: 74px;
  bottom: 0px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

#past-reports-parent {
  position: fixed;
  height: 100%;
  width: 100%;
}

#past-reports-background {
  position: relative;
  height: 100%;
}
