#submitted-contents {
  margin: auto;
}

#submitted-parent {
  width: 100%;
  display: flex;
  align-items: center;
}

#submitted-buttons {
  width: 178px;
}

.submitted-button {
  height: 47px;
  margin-top: 12px;
  margin-bottom: 6px;
}

#submitted-countdown {
  text-align: center;
  font-size: 12px;
  margin-bottom: 70px;
}

#submitted-image-parent {
  display: flex;
  margin-bottom: 12px;
}

#submitted-activity-summary {
  display: flex;
  p {
    font-size: 12px;
    color: black;
    margin: auto;
    cursor: pointer;
    border-bottom: 1px solid black;
  }
}

#submitted-image {
  margin: auto;
}

.submitted-sent-text {
  text-align: center;
  font-size: 14px;
}

#submitted-parent-wrapper {
  margin-top: 100px;
}
