.App {
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;
}

#app-header {
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0px;
}

#app-header-filler {
  height: 60px;
  margin-bottom: 7px;
}

.app-transition-slide-appear {
  transform: translateX(25%);
  opacity: 0;
}

.app-transition-slide-appear-active {
  transform: translateX(0%);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.app-transition-slide-exit {
  transform: translateX(0%);
}

.app-transition-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms;
}

.app-transition-slide-back-appear {
  transform: translateX(-25%);
  opacity: 0;
}

.app-transition-slide-back-appear-active {
  transform: translateX(0%);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

