#select-users-content {
  padding-top: 8px;
  position: fixed;
  top: 67px;
  bottom: 0px;
  width: 100%;
  overflow: auto;
}

.select-users-user-card-div {
  height: 70px;
  padding: 4px;
  width: 90%;
  margin: auto;
}

.select-users-add-card {
  height: 100%;
  display: flex;
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 32px;
  cursor: pointer;

  div {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 8px;
  }
}

.select-users-add-card-photo {
  width: 60px;
  height: 100%;
  display: flex;
  svg {
    height: 90%;
    width: 90%;
    overflow: visible;
    margin: auto;
  }
}

.select-users-add-card-photo-disabled {
  width: 60px;
  height: 100%;
  display: flex;
  svg {
    height: 90%;
    width: 90%;
    overflow: visible;
    margin: auto;
  }
  opacity: 0.5;
}

#select-users-add-text {
  font-size: 14px;
}

.select-users-add-input-parent {
  height: 70px;
  width: 95%;
  margin: auto;
}

.select-users-add-input-fields {
  display: flex;
  div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.select-users-add-input {
  flex: 1;
  margin-right: 8px;
  height: 100%;
  input {
    text-transform: uppercase;
  }
}

.select-users-add-input-button {
  width: 91px;
  float: right;
  height: 47px;
}

.select-users-add-input-hints {
  padding-top: 8px;
  width: 90%;
  margin: auto;
  font-size: 12px;

  p {
    margin: 0;
    opacity: 0.5;
  }
}

#select-users-add-error {
  color: #ce1226;
  opacity: 1 !important;
}

#select-users-title {
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
}

#select-users-spinner {
  position: absolute;
  display: flex;
  right: 32px;
  top: auto;
  bottom: auto;
  top: 0;
  bottom: 0;

  div {
    margin-top: auto;
    margin-bottom: auto;
  }
}
