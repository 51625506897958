.user-card-drop-down-parent {
  height: 100%;
  width: 100%;
  display: flex;
  margin: auto;
  cursor: pointer;

  img {
    margin: auto;
  }
}
