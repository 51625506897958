#summary-navbar-parent {
  width: 90%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  height: 49px;
  margin: auto;
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  div {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: auto;
    margin-right: 15px;
  }
}

#summary-navbar-parent-small {
  width: 100%;
  height: 49px;
  margin: auto;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  div {
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;
    text-align: center;
  }
}

.summary-navbar-item-wrapper {
  opacity: 1 !important;
  flex-basis: 0;
  flex-grow: 1;

  div {
    width: 100%;
    display: inline-block;
  }
}

.summary-navbar-item {
  border-bottom: solid 2px transparent;
  cursor: pointer;
}

.summary-navbar-item-selected {
  color: #ce1226;
  border-bottom: solid 2px #ce1226;
  opacity: 1 !important;
}
