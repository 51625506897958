#button-footer-parent {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: white;
  // box-shadow: 0 0 4px 0 black;
}

.button-footer-export-button {
  width: 196px;
  height: 47px;
  margin: auto;
  vertical-align: middle;
}

.button-footer-export-button-text {
  margin: 0;
  font-size: 14px;
  // height: 1em;
}

.button-footer-export-button-icon {
  height: 1.2em;
  padding-left: 1em;
}
