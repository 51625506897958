.past-reports-table-header-parent {
  width: 75%;
  height: 20px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
  margin: auto;
}
