#header-layout-parent {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
}

#header-layout-contents {
  margin: auto;
  width: 90%;
  text-align: center;
}

.header-layout-column {
  display: flex;
}

#header-layout-back {
  margin: auto;
  cursor: pointer;
}

#header-layout-title {
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: black;
}

#header-layout-sidebar {
  margin: auto;
  cursor: pointer;
}

#header-layout-emphasis {
  color: #ce1126;
}
