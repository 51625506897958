.past-reports-list-item-row {
  height: 30px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin: auto;
}

.past-reports-list-item-row:not(.unclickeable) {
  cursor: pointer;
}

.past-reports-list-item-col {
  display: flex;
  div {
    margin: auto;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

