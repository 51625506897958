#challenge-or-no-challenge-parent{
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
}

#challenge-or-no-challenge-buttons {
    margin: auto;
    width: 178px;
}

.challenge-or-no-challenge-button {
    height: 47px;
    margin-top: 15px;
    margin-bottom: 15px;
}
