.user-card {
  height: 100%;
  display: flex;
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.user-card-selected {
  height: 100%;
  display: flex;
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.user-card-contents {
  display: flex;
  flex: 1;
  div {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 8px;
  }
}

.user-card-photo {
  width: 60px;
  height: 100%;
  display: flex;
  svg {
    height: 90%;
    width: 90%;
    overflow: visible;
    margin: auto;
  }
}

.user-card-photo-small {
  width: 36px;
  height: 100%;
  display: flex;
  svg {
    height: 90%;
    width: 90%;
    overflow: visible;
    margin: auto;
  }
}

.user-card-name {
  font-size: 16px;
  font-weight: 500;
}

.user-card-name-small {
  font-size: 14px;
}

.user-card-more {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  height: 100%;
}

.user-card-dropdown-div {
  display: flex;
  height: 100%;
  margin: auto;
  width: 32px;
}

.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #f0f0f0 !important;
  color: black;
}

.dropdown-item {
  font-size: 14px;
}
