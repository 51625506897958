.buttongroup-col-buttons {
  margin-top: 0.8em;
  padding-left: 8px;
  padding-right: 8px;
}

.buttongroup-title {
  text-align: center;
  margin-bottom: 0;
}

.buttongroup-title-left {
  text-align: left;
  margin-bottom: 0;
}

.buttongroup-container {
  margin-top: 1em;
  width: 100%;
}

.buttongroup-half-button-div {
  width: 50%;
}

.buttongroup-row {
  margin: auto;
}
