#sidebar-parent {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0px;
  pointer-events: none;
}

#sidebar-contents {
  width: 432px;
  height: 100%;
  box-shadow: -3px 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  pointer-events: auto;
  position: relative;
}

.sidebar-top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  #sidebar-images {
    margin-top: 20px;
    margin-right: 24px;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img[alt="Close"] {
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  #sidebar-store-id {
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 165px;
  }
}

#sidebar-filler {
  width: 100%;
  background: transparent;
  pointer-events: none;
}

#sidebar-overlay {
  width: 100%;
  background: transparent;
  pointer-events: auto;
}

.sidebar-transition-enter {
  transform: translateX(100%);
}

.sidebar-transition-enter-active {
  transform: translateX(0%);
  transition: transform 300ms;
}

.sidebar-transition-exit {
  transform: translateX(0%);
}

.sidebar-transition-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

#sidebar-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

#sidebar-logout {
  text-align: right;
  position: fixed;
  bottom: 10px;
  right: 24px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  cursor: pointer;
}

#sidebar-logout-2 {
  text-align: right;
  position: fixed;
  bottom: 40px;
  right: 24px;
  font-family: Nexa;
  font-size: 14px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

#sidebar-switch-user-div {
  height: 100%;
  overflow: scroll;
  margin-bottom: 50px;
  box-shadow: inset 0px -4px 4px -4px rgba(0, 0, 0, 0.2);
}

.sidebar-user-card-div {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  height: 45px;
}

#sidebar-switch-user-header {
  display: flex;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 80%;
}

#sidebar-switch-user-text {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}

#sidebar-items {
  margin-bottom: 11px;
}

#sidebar-dropdown-menu {
  transform: translate3d(-150px, 16px, 0px) !important;
}

.sidebar-items-disabled {
  opacity: 0.5;
  position: relative;
  div {
    cursor: not-allowed !important;
  }
}

.sidebar-items-popup {
  width: 160px;
  color: #ce1126;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin-left: -80px;
  bottom: 0;
  right: 0;
}

#sidebar-online-offline {
  position: absolute;
  bottom: 10px;
  left: 18px;
  display: flex;

  span {
    font-size: 14px;
    color: black;
  }
}

.helpicon-sidebar {
  height: 1.5em;
  margin-right: 0.4em;
}

.sidebar-help {
  text-align: right;
  position: fixed;
  bottom: 20px;
  right: 24px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  cursor: pointer;
}

#sidebar-wifi{
  position: absolute;
  right: 50px;
  top: 13px;
  font-size: 14px;
  z-index: 9999999999999999999;
}

.online-icon {
  vertical-align: top;
  height:1.2em;
  width:1.2em;
  margin-left: 0.5em;
}