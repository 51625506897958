.sidebar-item-parent {
  height: 55px;
  display: flex;
  margin-left: 16px;
  cursor: pointer;
}

.sidebar-item-image-parent {
  margin-top: auto;
  margin-bottom: auto;
  width: 20%;
  display: flex;

  img {
    margin: auto;
  }
}

.sidebar-item-text-parent {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  width: 80%;
}

.sidebar-item-text {
  width: 141px;
  height: 19px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}
