#summary-parent {
  display: flex;
  flex-direction: column;
}

#summary-table {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 150px;
}

#summary-footer {
  position: fixed;
  width: 100%;
  height: 74px;
  bottom: 0px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

#summary-footer-button-contents {
  display: flex;
  justify-content: center;

  div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;

    g {
      stroke: white !important;
    }
  }
}
