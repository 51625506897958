// Father of all SCSS files, everything is imported here
@import "assets";
@import "variables";

//Views, Components & Containers
@import "./views/all";
@import "./components/all";
@import "./containers/all";

//Global Styles Including Style Reset

.update {
  overflow: hidden;
  text-align: center;
  width: 100%;
  font-family: "nexa";
  background-color: #1a1919;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8em 1.2em;
  text-decoration: none;
}
