.button-unselected,
.button-unselected:disabled {
  width: 100%;
  height: 100%;
  border: 0.05em solid black;
  border-radius: 2em;

  background-color: white;
  color: black;
}

.button-unselected:disabled {
  opacity: 0.3;
}

.button-unselected:hover,
.button-unselected:active {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.button-green {
  width: 100%;
  height: 100%;
  border: 0.05em solid #34b233;
  border-radius: 2em;
  background-color: #34b233;
  color: white;
}

.button-green:hover,
.button-green:active {
  background-color: #34b233 !important;
  color: white !important;
  border-color: #34b233 !important;
}

.button-red {
  width: 100%;
  height: 100%;
  border: 0.05em solid #ce1126;
  border-radius: 2em;
  background-color: #ce1126;
  color: white;
}

.button-red:hover,
.button-red:active {
  background-color: #ce1126 !important;
  color: white !important;
  border-color: #ce1126 !important;
}

.button-red-disabled {
  width: 100%;
  height: 100%;
  border: 0.05em solid #ce1126 !important;
  border-radius: 2em;
  color: white;
  // box-shadow: 0 0 4px 0 black;
  background-color: #ce1126 !important;
  opacity: 0.5 !important;
}

.button-green:disabled {
  width: 100%;
  height: 100%;
  border: 0.05em solid #34b233;
  border-radius: 2em;
  background-color: #34b233;
  color: white;
}

.button-selected {
  width: 100%;
  height: 100%;
  border-radius: 2em;

  background-color: black !important;
  box-shadow: none !important;
  border: 0.05em solid black !important;
  opacity: 1 !important;
}

.button-selected:disabled {
  width: 100%;
  height: 100%;
  border-radius: 2em;

  background-color: black !important;
  box-shadow: none !important;
  border: 0.05em solid black !important;
  opacity: 0.7 !important;
}

.button-black-disable,
.button-black-disable:disabled {
  width: 100%;
  height: 100%;
  border-radius: 2em;

  background-color: black !important;
  box-shadow: none !important;
  border: 0.05em solid black !important;
}

.button-p-text {
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  font-size: 14px;
  // Cut off button text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-style-unselected,
.no-style-unselected:hover,
.no-style-unselected:active {
  background: none !important;
  color: black !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.no-style-selected {
  background: none;
  background-color: inherit !important;
  box-shadow: none !important;
  border: none !important;
  color: #ce1226 !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.generic-button-default {
  height: 47px !important;
}
