//All third Party Frameworks & Assets 
//Syntax for import includes the tilde sign (from node modules) @import "~[framework file path]";
@font-face {
    font-family: "nexa";
    font-style: normal;
    font-weight: 400;
    src: local('nexa'), url('../fonts/nexa-regular.woff') format('woff');
}

@font-face {
    font-family: "nexa";
    font-weight: 200;
    src: local('nexa'), url('../fonts/nexa-light.woff') format('woff');
}

@font-face {
    font-family: "nexa";
    font-weight: 500;
    src: local('nexa'), url('../fonts/nexa-bold.woff') format('woff');
}

@font-face {
    font-family: "nexa";
    font-weight: 900;
    src: local('nexa'), url('../fonts/nexa-heavy.woff') format('woff');
}

@font-face {
    font-family: "opensans";
    font-style: normal;
    font-weight: 400;
    src: local('opensans'), url('../fonts/open-sans-v16-latin-regular.woff') format('woff');
}

@font-face {
    font-family: "opensans";
    font-weight: 300;
    src: local('opensans'), url('../fonts/open-sans-v16-latin-300.woff') format('woff');
}

@font-face {
    font-family: "opensans";
    font-weight: 500;
    src: local('opensans'), url('../fonts/open-sans-v16-latin-700.woff') format('woff');
}

@font-face {
    font-family: "opensans";
    font-weight: 900;
    src: local('opensans'), url('../fonts/open-sans-v16-latin-800.woff') format('woff');
}