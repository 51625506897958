#login-title {
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  #login-refusal {
    color: #ce1126;
  }
  h1 {
    font-weight: 500;
    margin: 0;
  }
}

#login-contents {
  margin: auto;
}

#login-button-parent {
  display: flex;
  margin-top: 145px;
}

#login-button {
  width: 178px;
  height: 47px;
  margin: auto;
}

#login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
  flex-direction: column;
}

#login-text {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-top: 16px;
}
