#export-reports-form-parent {
  width: 100%;
  margin: auto;
  margin-top: 100px;
}

#export-reports-footer {
  position: fixed;
  height: 74px;
  width: 100%;
  bottom: 0px;
}
