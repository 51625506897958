.export-report-date-picker-parent {
  margin-top: 1em;
}

.export-report-date-picker-title {
  text-align: left;
  margin-bottom: 0.5em;
}

.export-report-date-picker-col-center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.export-report-date-picker-date-display {
  border-bottom: 0.05em solid #707070;
  padding: 0;
}

.export-report-date-options {
  margin-bottom: 0.5em;
}

.export-report-date-picker-date-title {
  padding: 0;
}

.export-report-date-picker-date-display-text {
  margin-bottom: 0;
  color: #707070;
  display: flex;

  input {
    flex: 1;
    border: none;
  }

  img {
    width: 30px;
  }
}

.export-report-date-picker-date-display-text-icon {
  height: 1.5em;
  float: right;
  padding-bottom: 0.2em;
}

.export-report-date-picker-calendar {
  width: 90%;
  margin: auto;
  margin-top: 5%;
}
