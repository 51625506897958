.challenge-list-item {
  margin-top: 13px;
  padding-bottom: 20px;
}

.challenge-list-transition-enter {
  opacity: 0;
  transform: translateY(50px);
}

.challenge-list-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms, transform 300ms;
}

.challenge-list-transition-exit {
  opacity: 1;
  transform: scaleY(100%);
}

.challenge-list-transition-exit-active {
  opacity: 0;
  transform: scaleY(0%);
  transition: opacity 0ms, transform 300ms;
}

#challenge-description-field {
  width: 80%;
  margin: auto;
}

#challenge-submit {
  width: 150px;
  margin: auto;
}

#challenge-parent {
  padding-top: 2px;
}
