#past-reports-header-parent {
  height: 50px;
  display: flex;
  width: 100%;
}

#past-reports-header-row {
  margin: auto;
  width: 100%;
}

.past-reports-header-col {
  display: flex;
  div {
    margin: auto;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
  }
}
