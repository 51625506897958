#summary-table-parent {
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  background-color: white;
}

#summary-table-date-picker {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 60%;

  div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

#summary-table-date {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

#summary-table-date-arrow-wrapper {
  width: 16px;
  height: 16px;
}

.summary-table-date-arrow {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  img {
    margin: auto;
  }
}

#summary-table-stats {
  margin: auto;
  margin-top: 10px;
  font-size: 16px;
  flex-direction: column;

  tr {
    height: 36px;
  }
}

.summary-table-entry-title {
  text-align: right;
  padding-right: 25px;
  font-weight: 500;
}

.summary-table-entry-data {
  width: 20%;
  font-weight: normal;
}

.summary-table-entry-total {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  color: #ce1226;
}
