#view-report-parent {
  display: flex;
  flex-direction: column;
}

.view-report-list-item {
  width: 100%;
  margin-bottom: 1em;
}

#view-report-description {
  margin: auto;
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  background-color: #F0F0F0;
  div {
    font-size: 18px;
    text-align: center;
  }
  .description-shift {
    padding-left: 80px;
  }
  #report-view-close {
    padding-right: 40px;
    img {
      margin-top: 10px;
    }
  }
}

#view-report-description-contents {
  flex: 1;
}

.view-report-nav-button-parent {
  width: 16px;
  margin: auto;
}

.view-report-nav-button {
  width: 100%;
  display: flex;
  cursor: pointer;

  img {
    margin: auto;
  }
}

#view-report-text-field {
  width: 80%;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 32px;
}

#view-report-id {
  font-weight: 500;
}

#view-report-submitted-by {
  text-align: center;

  div {
    margin-bottom: 1em;
  }

  span {
    font-size: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

#view-report-submitted-by-bold {
  font-weight: 500;
}
