.input-field-parent {
  width: 100%;
  height: 47px;
  border-radius: 25px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
}

.input-field {
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 14px;
  border: 0;
}
