.helpfooter-container {
    position: fixed;
    bottom:0;
    right:0;
    margin-bottom: 2em;
    margin-right: 2em;
}

a.helpfooter-text, a.helpfooter-text:hover {
    color: black;
    text-decoration: none;
}

.helpicon {
    height: 2em;
    margin-right: 0.4em;
}