.select-store-dropdown {
    display: block;
    height: 100%;
    margin: auto;
    width: 40%;
    color: black;
    background: white;
    border: black;
    box-shadow: none !important;
    z-index: 2;
}

#app-transition-slide {
  margin-bottom: 67px;
  margin-top: 10px;
}

#view-report-parent {
  margin-bottom: 67px;
  margin-top: 10px;
}