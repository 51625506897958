#filter-users-dropdown-div {
  width: 90%;
  height: auto;
  margin: auto;
  position: relative;
}

#filter-users-dropdown {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

#filter-users-dropdown-toggle-layout {
  display: flex;
  cursor: pointer;
  div {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    font-size: 16px;
  }
}

#filter-users-dropdown-spinner {
  position: absolute;
  display: flex;
  right: 32px;
  top: auto;
  bottom: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

#filter-users-dropdown-offline-text {
  opacity: 0.5;
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding-bottom: 16px;
}
