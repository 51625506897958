#form-input-parent {
  display: flex;
  flex-direction: column;
  margin: auto;
}

#form-input-top {
  margin: auto;
  width: 212px;
  margin-top: 1em;
  margin-bottom: 1em;
}

#form-input-bottom {
  margin: auto;
  width: 212px;
  margin-bottom: 2em;
}
