#header-parent {
  height: 100%;
}

#header-contents-parent {
  margin-bottom: 7px;
  height: 67px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
}
