.large-input,
.large-input:disabled {
  width: 100%;
  height: 85px;
  border: 0.05em solid black;
  border-radius: 0.5em;
  background-color: white;
  padding: 5px;
  margin-top: 8px;
  font-size: 14px;
  color: black !important;
}
