.export-submitted-view-button {
  margin: auto;
  text-align: center;
  text-decoration: underline;

  a {
    color: black;
  }
}

#submitted-contents-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
}

#export-submitted-footer {
  position: fixed;
  height: 74px;
  width: 100%;
  bottom: 0px;
}

#export-submitted-contents {
  margin: auto;
  margin-top: 100px;
}
